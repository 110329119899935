import React, { useState, useEffect } from "react";
import ProductsContainer from '../../components/Containers/Products/ProductsContainer'
import TransactionContainer from '../../components/Containers/Transactions/TransactionContainer'
import TransactionGraph from '../../components/Containers/Transactions/TransactionGraph'
import NotificationsContainer from '../../components/Containers/Notifications/NotificationsContainer'
import AccountCompletion from '../../components/User/AccountCompletion/AccountCompletion'

import ExterNalProducts from '../../components/Products/External'

import TransactionModal from '../../components/Modals/TransactionModal'

// import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { UserContext } from "../../UserContext";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ExternalProductModal from "../../components/Modals/ExternalProductModal";
import AdervertisingModal from "../../components/Modals/AdervertisingModal";
import AdervertisingModal2 from "../../components/Modals/AdervertisingModal2";
import AdervertisingModal3 from "../../components/Modals/AdervertisingModal3";
import BotImage from "../../components/Bots/BotImage";
import PromotionBox from "../../components/promotion";
import PromotionModal from "../../components/Modals/PromotionModal";
import TourGuide from "../../components/Modals/Tours";
import PopoverTour from "../../components/Modals/Tours/PopoverTour";
import EducationContainer from "../../components/Containers/Education";

function Dashboard() {

  const { auth, user } = React.useContext(UserContext)
  const [userid, setUserid] = React.useState('');
  const [userData, setUserData] = React.useState(null);
  const [productData, setProductData] = React.useState([]);
  const [productInterest, setProductInterest] = React.useState([]);
  const [userKYC, setUserKYC] = React.useState([]);
  const [userActive, setuserActive] = React.useState('');

  const [modalOTP, setModalOTP] = React.useState({ otp: '', from: '', amount: '', type: 'deposit', reference: '' });
  const [depositModalDisplay, setDepositModalDisplay] = React.useState(false);
  const [canContinue, setCanContinue] = React.useState(true);

  const [displayModal, setDisplayModal] = React.useState(false);
  // Product Details
  const [displayProductModal, setDisplayProductModal] = React.useState(false);

  const [useAdvertising, setUseAdvertising] = React.useState(null);
  const [displayAdvertisingModal, setDisplayAdvertisingModal] = React.useState(false);


  const [useAdvertising2, setUseAdvertising2] = React.useState(null);
  const [displayAdvertisingModal2, setDisplayAdvertisingModal2] = React.useState(false);

  const [displayAdvertisingModal3, setDisplayAdvertisingModal3] = React.useState(false);
  const [useAdvertising3, setUseAdvertising3] = React.useState(null);

  const [displayPromotionModal, setDisplayPromotionModal] = React.useState(false);


  const [displayNoticeModal, setDisplayNoticeModal] = React.useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    border: '2px solid #00c4f4',
  };


  const getUserProductData = async (userid) => {

    var userObj = {
      userid: userid
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/all-user-open-products`, userObj).then(async (res) => {
      if (res.data.status == 200) {
        setProductData(res.data.total)
      }
    })

    return true;
  }

  const ficaStatus = {
    'N': 0,
    'P': 1,
    'A': 2,
  }

  const activeStatus = {
    'N': 0,
    'P': 1,
    'A': 2,
    'L': 3,
  }

  const currencyConvert = {
    'usdt': '1',
    'zar': '2',
    'btc': '3',
  }

  const [tourSteps, setTourSteps] = React.useState([]);
  const [allowTourClose, setAllowTourClose] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(true);

  const [showPopupTour, setShowPopupTour] = React.useState(false);
  const [tourObject, setTourObject] = React.useState({});


  React.useEffect(() => {

    if (auth != null) {
      if (!auth) {
        window.location.href = "/login"
      } else {
        setUserid(user.user.userid)
        getUserProductData(user.user.userid)
        setUserKYC(user.user.fica_status)
        setuserActive(user.user.u_status)
        setUserData(user.user)
        if (user.user.fica_status === 'N' && user.user.payment_status !== 'A') {
          // setDisplayModal(true)
          var tourObj = [

            {
              element: '.dashboard-title',
              popover:
              {
                title: 'Dashboard', description: 'Welcome to your central hub, where you have the power to accomplish anything.'
              }
            },
            {
              element: '.kyc-navigate-button',
              popover:
              {
                title: 'KYC Required', description: 'To seamlessly navigate and oversee your dashboard, a KYC verification is essential. Click the button below to proceed.'
              }
            },

          ]

          setTourSteps(tourObj)


        }
        if (user.user.fica_status === 'N' && user.user.payment_status === 'A') {
          // setDisplayModal(true)
          var tourObj = [

            {
              element: '.kyc-navigate-button',
              popover:
              {
                title: 'New KYC Requirements', description: `As part of our ongoing commitment to regulatory compliance and service excellence, we are
              reaching out to all our members, both business entities and individuals, to submit specific documentation urgently as this was part of our findings in our periodic compliance audits. Click the button below to proceed.`
              }
            },

          ]

          setTourSteps(tourObj)


        }
        if (user.user.fica_status === 'P' && user.user.payment_status !== 'A') {

          getDepositFunc(user.user.userid)
          var tourObj = [

            {
              element: '.kyc-status-tour',
              popover:
              {
                title: 'KYC Pending', description: 'While our admin team reviews your request, let us guide you through our dashboard.'
              }
            },
            {
              element: '.tour-account-info',
              popover:
              {
                title: 'Account Details',
                description: 'Explore a brief overview of your profile, featuring your user ID, account status, and KYC status.'
              }
            },
            {
              element: '.tour-external-products',
              popover:
              {
                title: 'External Products',
                description: 'Presenting an external product in our ecosystem: AI Token.'
              }
            },
            {
              element: '.tour-my-products',
              popover:
              {
                title: 'My Products',
                description: 'Explore all the products you&#39;ve acquired in one convenient space.'
              }
            },
            {
              element: '.tour-recent-notifications',
              popover:
              {
                title: 'Recent Notifications',
                description: 'Stay informed with a quick glance at your most recent notifications.'
              }
            },
            {
              element: '.tour-recent-transactions',
              popover:
              {
                title: 'Recent Transactions',
                description: 'View a snapshot of your most recent transactions in this section.'
              }
            },


          ]

          setTourSteps(tourObj)
          setAllowTourClose(true)

        }
        if (user.user.fica_status === 'A' && user.user.company !== '263' && user.user.company !== 263) {
          getAdvertisingMetaData(user.user.userid)
          // getAdvertisingMetaData2(user.user.userid)
        }

        if (user.user.u_status === 'A' && user.user.fica_status === 'A') {
          getFirstDeposit(user.user.userid)
        }
      }
    }
  }, [auth])

  const closeModal = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setDisplayModal(false)
  }

  const navifateToKYC = (event, reason) => {
    window.location.href = "/settings"
  }

  const closeDepositModal = () => {
    setDepositModalDisplay(false)
  }

  const openTransactionModal = () => {

    setDepositModalDisplay(true)

  }

  const handleAccept = async () => {


    if (modalOTP['amount'] <= 0) {
      toast.error('Please Enter Valid Amount', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    if (modalOTP['reference'] === '') {
      toast.error('Please Enter Valid Reference or TXID', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    if (canContinue) {
      setCanContinue(false)
      var otpObj = {
        userid: userid,
        otp: modalOTP['otp']
      }

      const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp", otpObj).then(async (res) => {

        if (res.data.status !== 200) {

          toast.error("Incorrect OTP, We Have Sent A New OPT!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          await sendOTP();

          setCanContinue(true)

          return false;
        } else {
          return true;
        }
      });

      if (!checkOtp)
        return false;

      const depositObject = {
        userid: userid,
        txid: modalOTP['reference'],
        amount: modalOTP['amount'],
        tran_cat: '1',
        currency: currencyConvert[modalOTP['from']],
        type: "Adding Deposit"
      }


      var createTransaction = await axios.post(process.env.REACT_APP_BASE + "api/create-transaction", depositObject).then(async res => {



        if (res.data.status == 200) {
          toast.success('Deposit Created Successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          setTimeout(() => { window.location.reload(); }, 2000);


        } else {
          toast.error('Unable To Create Deposit', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          setCanContinue(true)
        }

      });
    }

    // setModalDisplay(false)
  }

  const sendOTP = async () => {

    var otpObj = {
      userid: userid
    }

    await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp", otpObj).then((res) => {

      if (res.data.status == 200) {

        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

      } else {

        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    })

  }

  const getDepositFunc = async (useridToUse) => {
    var userObjDeposit = {
      userid: useridToUse,
    }

    const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-any-deposits", userObjDeposit).then(async (res) => {
      if (res.data.status !== 200) {
        setDepositModalDisplay(true)
      }
    });
  }

  // AIT
  const openProductModal = async () => {

    setDisplayProductModal(true)
  }

  const closeProductModal = async (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setDisplayProductModal(false)
  }



  const closeAdvertiseModal = async (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setDisplayAdvertisingModal(false)

    return await getAdvertisingMetaData3(userid, false)
  }


  const closeAdvertiseModal2 = async (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setDisplayAdvertisingModal2(false)

  }

  const closeAdvertiseModal3 = async (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setDisplayAdvertisingModal3(false)
    return await getAdvertisingMetaData2(userid, false)

  }

  const getAdvertisingMetaData = async (userid) => {
    //   var metadataObj = {
    //     userid: userid,
    //     meta_key: "advertising",
    //     default_value: "true"
    //   }

    //   const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/get-meta-create",metadataObj).then( async(res)=>{

    //     if(res.data.status === 200)
    //     {
    //       setUseAdvertising(res.data.body.meta_value)

    //       if(res.data.body.meta_value === true)
    //       {
    //         setDisplayAdvertisingModal(true)
    //         return await getAdvertisingMetaData2(userid, true)
    //       }

    //       return getAdvertisingMetaData3(userid, false)

    //     }



    // });
  }

  const getFirstDeposit = async (useridToUse) => {

    var depositObj = {
      userid: useridToUse
    }

    await axios.post(process.env.REACT_APP_BASE + "api/confirm-any-deposits", depositObj).then(async (res) => {

      if (res.data.status === 400) {
        // var tourObj = [

        //   { element: '.deposit-container', 
        //     popover: 
        //     { 
        //       title: 'Begin with Your First Deposit', 
        //       description: 'With your profile now active, take the first step by making your initial deposit. Click here and select "Deposit" to begin.',
        //       side: "left",
        //       align: 'start',
        //     } 
        //   },

        // ]

        var tourObj = {
          element: '.deposit-container',
          popover: {
            title: 'Begin with Your First Deposit',
            description: 'With your profile now active, take the first step by making your initial deposit. Click here and select "Deposit" to begin.',
            side: "left",
            align: 'end',
            allowClose: true,
          }
        };

        setTourObject(tourObj);
        setShowPopupTour(true);

        // setTourSteps(tourObj)
        // setShowProgress(false)
        // setAllowTourClose(true)
      }
    });

    //   var metadataObj = {
    //     userid: userid,
    //     meta_key: "advertising",
    //     default_value: "true"
    //   }

    //   const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/get-meta-create",metadataObj).then( async(res)=>{

    //     if(res.data.status === 200)
    //     {
    //       setUseAdvertising(res.data.body.meta_value)

    //       if(res.data.body.meta_value === true)
    //       {
    //         setDisplayAdvertisingModal(true)
    //         return await getAdvertisingMetaData2(userid, true)
    //       }

    //       return getAdvertisingMetaData3(userid, false)

    //     }



    // });

  }


  const getAdvertisingMetaData2 = async (userid, displayingAdvertising1) => {

    //   if(displayingAdvertising1)
    //     return;

    //   var metadataObj = {
    //     userid: userid,
    //     meta_key: "advertising_2",
    //     default_value: "true"
    //   }

    //   const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/get-meta-create",metadataObj).then( async(res)=>{

    //     if(res.data.status === 200)
    //     {
    //       setUseAdvertising2(res.data.body.meta_value)

    //       if(res.data.body.meta_value === true)
    //         setDisplayAdvertisingModal2(true)
    //     }

    // });
  }

  const getAdvertisingMetaData3 = async (userid, displayingAdvertising1) => {

    //   if(displayingAdvertising1)
    //     return;

    //   var metadataObj = {
    //     userid: userid,
    //     meta_key: "advertising_3",
    //     default_value: "true"
    //   }

    //   const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/get-meta-create",metadataObj).then( async(res)=>{

    //     if(res.data.status === 200)
    //     {
    //       setUseAdvertising3(res.data.body.meta_value)

    //       if(res.data.body.meta_value !== 0 )
    //         setDisplayAdvertisingModal3(true)
    //       else
    //         return getAdvertisingMetaData2(userid, false)
    //     }

    // });
  }

  const closePromotionModal = async (event, reason) => {

    if (reason && reason == "backdropClick")
      return;

    setDisplayPromotionModal(false)
  }

  // const openPromotionModal = async() => {
  //   setDisplayPromotionModal(true)
  // }

  const closeNoticeModal = async (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setDisplayNoticeModal(false);
  };

  const closeNoticeModalOnButtonClick = async () => {
    setDisplayNoticeModal(false);
  }



  return (
    <div className="container mx-auto px-2 mb-8">
      <TourGuide steps={tourSteps} allowClose={allowTourClose} showProgress={showProgress} />

      {showPopupTour && <PopoverTour popupInfo={tourObject} />}

      <Modal
  open={displayNoticeModal}
  onClose={closeNoticeModal}
  disableBackdropClick
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
  slotProps={{
    backdrop: {
      onClick: (event) => event.stopPropagation(), // Prevent modal from closing on outside click
    },
  }}
  className="form-no-background"
>
  <Box
    sx={{
      ...style,
      width: 400,
      maxHeight: '80vh', // Constrain the modal height
      overflow: 'auto', // Enable scroll if content is too large
      background: "rgba(5,0,5,0.8)",
    }}
  >
    <div className="overflow-auto p-4">
      <h2
        id="parent-modal-title"
        className='text-center text-base md:text-lg underline mb-3'
      >
        Update on System Transition and Processing Times
      </h2>

      <p className='md:text-base dashboard-popup'>
        Dear Valued Clients,<br /><br />

        We are currently in the process of transferring our database to a new and improved system. This upgrade is designed to enhance efficiency and ensure a smoother experience for withdrawals and payment compliance moving forward.<br />
        <br />
        While we complete this transition, we kindly ask for your patience and understanding as there may be slight delays. Our team is working diligently to finalize the process as quickly and seamlessly as possible.<br /><br />

        Thank you for your continued support as we improve our services to better meet your needs.<br /><br />

        If you have any questions or require further clarification, please don't hesitate to reach out.<br /><br />

        Best regards,<br />
        C1 Group Management
      </p>

      <Button
        style={{
          background: "#040E18",
          borderRadius: "70px",
          border: "2px solid #00c4f4",
          color: "#fff",
          marginTop: '20px',
        }}
        className='c1-primary-btn'
        onClick={closeNoticeModalOnButtonClick}
      >
        I Acknowledge
      </Button>
    </div>
  </Box>
</Modal>

      {/* Modal for KYC if it is still pending */}
      {/* <Modal
        open={displayModal}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"

        className="form-no-background"
      >
        <Box sx={{
          ...style, width: 400,
          background:
            "	rgba(5,0,5,0.8)"
        }}>
          <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>Notice</h2>

          <p id="parent-modal-description" className=" mb-4">
            {userKYC === 'N' ? 
              'Please complete the KYC process in order to make transactions and purchase a product.'
              : ''}
              
          </p>

          {userKYC === 'N' ?
          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #00c4f4",
            color: "#fff"}} className='c1-primary-btn' onClick={navifateToKYC}>Continue To KYC</Button> 
            : ''}   

          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeModal}>Close</Button>      
        </Box>
      </Modal> */}

      <ExternalProductModal displayModal={displayProductModal} closeProductModal={closeProductModal} />
      {/* <PromotionModal show={displayPromotionModal} closePromotionModal={closePromotionModal} /> */}

      {/* <AdervertisingModal3 user={userData} displayModal={displayAdvertisingModal3} closeAdvertiseModal={closeAdvertiseModal3}  />
      <AdervertisingModal user={userData} displayModal={displayAdvertisingModal} closeAdvertiseModal={closeAdvertiseModal}  />
      <AdervertisingModal2 user={userData} displayModal={displayAdvertisingModal2} closeAdvertiseModal={closeAdvertiseModal2}  /> */}

      {/* <TransactionModal modalDisplay={depositModalDisplay} modalOTP={modalOTP} closeModal={closeDepositModal} handleAccept={handleAccept} title="Deposit" /> */}
      <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white dashboard-title">Dashboard</h1>

      <div className='spacercontainer' />

      {/* <hr style={{color: "#12d0ff"}} className="mb-6" /> */}
      {/* <div className="grid grid-cols-1 gap-6  mb-3">
            <PromotionBox openPromotionModal={openPromotionModal} />
      </div> */}

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 mb-3 account-completion">

        <AccountCompletion userid={userid} kyc={ficaStatus[userKYC]} account={activeStatus[userActive]} openProductModal={openProductModal} />


        {/* <BotImage /> */}
        <ExterNalProducts openProductModal={openProductModal} />

      </div>

      <div className='spacercontainer' />

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 mb-3 account-completion">
        <div className="products-tour">
          <ProductsContainer />
        </div>

        <div>
          <EducationContainer />
        </div>
      </div>

      <div className='spacercontainer' />

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">

        <NotificationsContainer />

        <TransactionContainer filter={false} showAll={false} />
        {typeof productData == 'undefined' && productData.length > 0 ?
          <TransactionGraph productData={productData} />
          : ''}

      </div>


      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 mb-6">



      </div>

    </div>
  )
}

export default Dashboard